import styled from '@emotion/styled';
import type { FC, HTMLProps } from 'react';
import { useTranslation } from '@pafcloud/i18n';
import { Icon } from '@pafcloud/base-components';
import { LoyaltyProgramColors } from '../loyalty-program-colors';
import { getProgressDetails } from './getProgressDetails';

type ProgressBarProps = HTMLProps<'progress'> & {
  'aria-valuenow': number;
  'aria-valuemin'?: number;
  'aria-valuemax': number;
};

const borderRadius = 4;
const innerOffset = 2;
const innerBorderRadius = borderRadius - innerOffset;

const ProgressBarContainer = styled.div({
  position: 'relative',
  width: '100%',
  height: 20,
  padding: innerOffset,
  border: LoyaltyProgramColors.ProgressBarBorder,
  boxShadow: LoyaltyProgramColors.ProgressBarGlow,
  borderRadius: borderRadius,
  background: `${LoyaltyProgramColors.ProgressBarBackground}`,
});

const ProgressBar = styled.progress<ProgressBarProps>({
  WebkitAppearance: 'none',
  appearance: 'none',
  display: 'block',
  width: '100%',
  height: '100%',
  borderRadius: innerBorderRadius,

  '&[value]::-webkit-progress-value': {
    transition: 'width 0.3s ease-in-out',
  },

  '::-webkit-progress-bar': {
    borderRadius: innerBorderRadius,
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    background: `${LoyaltyProgramColors.ProgressBarBackground}`,
  },

  '::-webkit-progress-value': {
    borderRadius: innerBorderRadius,
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    background: `${LoyaltyProgramColors.ProgressBarValue}`,
    boxShadow: LoyaltyProgramColors.ProgressBarValueGlow,
  },
  '::-moz-progress-bar': {
    borderRadius: innerBorderRadius,
    background: `${LoyaltyProgramColors.ProgressBarValue}`,
    boxShadow: LoyaltyProgramColors.ProgressBarValueGlow,
  },
});

const MaintainLevel = styled.div<{ maintainPosition: number; isMaxLevel: boolean; 'data-maintained': boolean }>(
  ({ maintainPosition, isMaxLevel }) => ({
    position: 'absolute',
    top: 0,
    left: isMaxLevel ? 'unset' : `${maintainPosition}%`,
    right: isMaxLevel ? '0' : 'unset',
    bottom: 0,
    textAlign: 'center',

    ...(!isMaxLevel && {
      '::before': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        bottom: 0,
        background: LoyaltyProgramColors.ProgressMaintainDivider,
        width: 2,
        zIndex: 0,
      },
    }),
  }),
);

const MaintainValue = styled.span<{ isMaxLevel: boolean }>(({ isMaxLevel }) => ({
  position: 'relative',

  transform: isMaxLevel ? 'translate(0%, -150%)' : 'translate(-50%, -100%)',
  zIndex: 1,
  display: 'grid',
  gridAutoFlow: 'column',
  placeItems: 'center',
  marginTop: '0.5rem',
  padding: '0 4px',
  borderRadius: 2,
  background: LoyaltyProgramColors.ProgressMaintain,
  border: LoyaltyProgramColors.ProgressMaintainBorder,

  fontSize: '0.85rem',
  fontWeight: 'bold',
  color: LoyaltyProgramColors.ProgressMaintainText,

  '[data-maintained="true"] &': {
    background: LoyaltyProgramColors.ProgressMaintainCompleted,
    boxShadow: LoyaltyProgramColors.ProgressMaintainCompletedGlow,
    color: LoyaltyProgramColors.ProgressMaintainCompletedText,
  },
}));

const LockIcon = styled(Icon)({
  marginTop: -1,
  marginRight: 2,
  color: 'currentcolor',
});

type Props = {
  requiredValue: number;
  requiredPoints: number;
  maintainPoints: number;
  isMaxLevel: boolean;
};

export const LoyaltyProgramProgressBar: FC<Props> = ({ requiredValue, requiredPoints, maintainPoints, isMaxLevel }) => {
  const { t } = useTranslation('loyalty-program');

  const { requiredPointsOrMaintainPoints, maintainPercentage, maintainCompleted } = getProgressDetails(
    isMaxLevel,
    maintainPoints,
    requiredValue,
    requiredPoints,
  );
  const showMaintainPoints = maintainPoints > 0;

  const isMaxLevelAndShouldNotShowMaintain = isMaxLevel && !showMaintainPoints;

  const progressCurrentValue = isMaxLevelAndShouldNotShowMaintain ? 1 : requiredValue;
  const progressRequiredValue = isMaxLevelAndShouldNotShowMaintain ? 1 : requiredPointsOrMaintainPoints;

  return (
    <ProgressBarContainer>
      <ProgressBar
        aria-label={t('progress-bar.gain.aria-label')}
        aria-valuemax={progressRequiredValue ?? 0}
        aria-valuenow={progressCurrentValue ?? 0}
        max={progressRequiredValue ?? 0}
        value={progressCurrentValue ?? 0}
      />
      {showMaintainPoints && (
        <MaintainLevel
          maintainPosition={maintainPercentage}
          isMaxLevel={isMaxLevel}
          data-maintained={maintainCompleted}
        >
          <MaintainValue isMaxLevel={isMaxLevel}>
            <LockIcon name="lock" size="1em" />
            {maintainPoints}
          </MaintainValue>
        </MaintainLevel>
      )}
    </ProgressBarContainer>
  );
};
