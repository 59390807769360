/**
 * @generated SignedSource<<ebf35eaf12a1d81cb049cc74f0294d32>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useCalculateProgress_player$data = {
  readonly loyaltyProgram: {
    readonly level: number;
    readonly monthlyInitialLevel: number;
    readonly monthlyPoints: number;
  } | null;
  readonly " $fragmentType": "useCalculateProgress_player";
};
export type useCalculateProgress_player$key = {
  readonly " $data"?: useCalculateProgress_player$data;
  readonly " $fragmentSpreads": FragmentRefs<"useCalculateProgress_player">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "useCalculateProgress_player",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "LoyaltyProgramPlayer",
      "kind": "LinkedField",
      "name": "loyaltyProgram",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "level",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "monthlyInitialLevel",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "monthlyPoints",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Player",
  "abstractKey": null
};

(node as any).hash = "80b1a49f0405189927905840dbcaf0a6";

export default node;
