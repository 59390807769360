import type { FC } from 'react';
import styled from '@emotion/styled';
import { graphql, useFragment } from 'react-relay/hooks';
import { Image } from '@pafcloud/base-components';
import type { LoyaltyProgramLevelImages_image$key } from './__generated__/LoyaltyProgramLevelImages_image.graphql';

const loyaltyProgramLevelImagesFragment = graphql`
  fragment LoyaltyProgramLevelImages_image on LoyaltyProgramLevel {
    # Aliased to switch image to non-nullable type, can be switch back to
    # non-aliased after some time.
    badge: image {
      url
      width
      height
    }
    title
    description
  }
`;

const Img = styled(Image)({
  display: 'block',
  objectFit: 'contain',
  maxWidth: '100%',
  height: 'auto',
});

type Props = {
  levelImage: LoyaltyProgramLevelImages_image$key;
};

export const LoyaltyProgramLevelImage: FC<Props> = ({ levelImage }) => {
  const { badge, title } = useFragment(loyaltyProgramLevelImagesFragment, levelImage);

  if (badge == null) {
    return null;
  }

  return <Img src={badge.url} width={badge.width} height={badge.height} alt={title} />;
};
