import { Color } from '@pafcloud/style';

const conicGradient = `
conic-gradient(
  at -10% 80%,
  ${Color.Primitive.Primary}30 1%,
  ${Color.Primitive.Accent}20 22%,
  transparent 25%
)
`;

export const Colors = {
  Text: Color.TextColors.BodyText,
  Link: Color.TextColors.BodyText,

  ProcessCardBackground: Color.Surface.Nested.Background,
  ProgressContainerBackground: Color.Surface.Base.Background,
  ProcessCardProgress: Color.Primitive.Accent, // The animated border

  ProgressBarBorder: `1px solid ${Color.Surface.Nested.Dimmed}`,
  ProgressBarGlow: undefined,
  ProgressBarBackground: Color.Surface.Nested.Background,
  ProgressBarValue: `linear-gradient(180deg, ${Color.Primitive.PrimaryTint} 0%, ${Color.Primitive.Primary} 25%, ${Color.Primitive.Primary})`,
  ProgressBarValueGlow: `0 0 22px 2px ${Color.Primitive.Primary}c0, 3px -3px 13px 0px ${Color.Primitive.PrimaryTint}60`,

  ProgressMaintain: Color.Surface.Floating.Background,
  ProgressMaintainText: Color.Surface.Floating.Foreground,
  ProgressMaintainBorder: `1px solid ${Color.TextColors.BodyText}80`,
  ProgressMaintainDivider: `${Color.TextColors.BodyText}80`,
  ProgressMaintainCompleted: Color.Primitive.Primary,
  ProgressMaintainCompletedText: Color.Primitive.PrimaryContrast,
  ProgressMaintainCompletedGlow: Color.Elevation.Level2,

  ProgressSectionBackground: conicGradient,
  ProgressSectionDivider: `1px solid ${Color.Surface.Base.Dimmed}`,

  TotalText: Color.Primitive.Accent,
  HeadingStyle: 'italic',
  Heading: Color.TextColors.HeadingText,

  WidgetBackgroundImage: conicGradient,
};
