import styled from '@emotion/styled';
import type { FC } from 'react';
import { useTranslation } from '@pafcloud/i18n';
import { Font } from '@pafcloud/style';
import { $buildEnv } from '@pafcloud/config/src/buildEnv';
import { LoyaltyProgramColors } from '../loyalty-program-colors';
import { LoyaltyProgramLevelImage } from './LoyaltyProgramLevelImage';
import type { LoyaltyProgramLevelImages_image$key } from './__generated__/LoyaltyProgramLevelImages_image.graphql';
import { getProgressDetails } from './getProgressDetails';

const LevelInformation = styled.div({
  display: 'flex',
  width: '100%',
  marginBottom: 8,
  marginTop: 8,
  lineHeight: 1,
});

const LevelImage = styled.figure({
  gridRow: '1 / span 2',
  display: 'inline-block',
  width: '3em',
  margin: 0,
});

const LevelMultiplier = styled.span({
  alignSelf: 'end',

  fontSize: '0.85em',
  color: LoyaltyProgramColors.Text,
});

const Level = styled.span({
  width: '100%',
  alignSelf: 'center',
  marginLeft: 8,
  display: 'flex',
  justifyContent: 'space-between',
});

const Points = styled.span({
  fontFamily: Font.Heading,
  textAlign: 'right',
  fontSize: '1em',
  fontWeight: $buildEnv.theme === 'momentum' ? 900 : 400,
  color: LoyaltyProgramColors.Text,
});

type Props = {
  levelImage: LoyaltyProgramLevelImages_image$key | null;
  maintainPoints: number;
  requiredValue: number;
  requiredPoints: number;
  levelMultiplier: number | null;
  isMaxLevel: boolean;
};

export const LoyaltyProgramProgressInformation: FC<Props> = ({
  levelImage,
  maintainPoints,
  requiredValue,
  requiredPoints,
  levelMultiplier,
  isMaxLevel,
}) => {
  const { t } = useTranslation('loyalty-program');

  const { maintainCompleted } = getProgressDetails(isMaxLevel, maintainPoints, requiredValue, requiredPoints);

  return (
    <LevelInformation>
      {levelImage && (
        <LevelImage>
          <LoyaltyProgramLevelImage levelImage={levelImage} />
        </LevelImage>
      )}

      <Level>
        {levelMultiplier && (
          <LevelMultiplier>{t('progress.multiplier', { multiplier: levelMultiplier })}</LevelMultiplier>
        )}
        <Points>
          {isMaxLevel ? (
            <strong>{maintainCompleted ? t('max-level.secured') : t('max-level.reached')}</strong>
          ) : (
            <>
              <strong>{requiredValue}</strong> / {requiredPoints}
            </>
          )}
        </Points>
      </Level>
    </LevelInformation>
  );
};
