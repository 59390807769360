export const getProgressDetails = (
  isMaxLevel: boolean,
  maintainPoints: number,
  requiredValue: number,
  requiredPoints: number,
) => {
  const requiredPointsOrMaintainPoints = isMaxLevel ? maintainPoints : requiredPoints;
  const requiredProgressPercentage = (requiredValue / requiredPointsOrMaintainPoints) * 100;
  const maintainPercentage = (maintainPoints / requiredPointsOrMaintainPoints) * 100;
  const maintainCompleted = requiredProgressPercentage >= maintainPercentage;

  return { requiredPointsOrMaintainPoints, maintainPercentage, maintainCompleted };
};
